import React, { FC } from 'react';
import { Accordion, Footer, Header } from '../../common';
import { faqChips } from './Subs';

import './FAQPage.scss';

export const FAQPage: FC = () => (
  <div className="faq-page common-page">
    <Header />
    <div className="faq-page__container common-page__container">
      <div className="common-page__triangle-block faq-page__triangle-block">
        <h5 className="common-page__mini-header faq-page__triangle-block-mini-header">Вопросы</h5>

        <h2 className="common-page__main-header faq-page__triangle-block-header">Часто задаваемые <br /> вопросы</h2>
      </div>

      <Accordion className="faq-page__accordion" chips={faqChips} />
    </div>
    <Footer />
  </div>
);