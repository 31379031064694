import React, { FC } from 'react';
import cn from 'classnames';

import './Pulse.scss';

interface Props {
  className?: string;
}

export const Pulse: FC<Props> = ({ className = '' }) => (
  <div className={cn('Pulse-container', className)}>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
    <div className="Pulse-item"></div>
  </div>
);
