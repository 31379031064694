import React, { FC } from 'react';
import InputMask from 'react-input-mask';

import './Input.scss';

interface InputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const Input: FC<InputProps> = ({ onChange, value }) => (
  <div className="input">
    <p className="text">+7</p>
    <InputMask onChange={onChange} value={value} className="input__input" mask="(999) 999-99-99" placeholder="(123) 456-78-90" />
  </div>
);
