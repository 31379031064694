import React, { FC, Fragment, useMemo } from 'react';

import { AppGallery, AppStoreIcon, Footer, Header, LogoBigIcon, PlayStoreIcon, Point } from '../../../common';

export const Mobile: FC = () => {

  const Logo = useMemo(() => {
    if (/Android/i.test(navigator.userAgent)) return <PlayStoreIcon />

    return /iPhone|iPad|iPod/i.test(navigator.userAgent) ? <AppStoreIcon /> : <AppGallery />
  }, []);

  return (
    <Fragment>
      <Header />

      <h1 className="main-page__header">
        Нет времени на знакомства? <br />
        <span>Наш алгоритм все сделает за тебя</span>
        {/* <span>Moscow & St. Petersburg</span> */}
      </h1>

      <div className="main-page__phone-container">
        <div className="main-page__store">
          {Logo}
          <LogoBigIcon />
        </div>

        <div className="main-page__points-container">
          <Point iconType="shield">
            <strong>Отсеивает фейки</strong> <br /> Двойная верификация пользователей
          </Point>
          <Point iconType="calendar" reverse={true}>
            <strong>Легко знакомит</strong> <br /> Каждый день — три<br /> анкеты
          </Point>
          <Point iconType="person">
            <strong>Подбирает наилучших</strong> <br /> Учитывается 12<br /> параметров
          </Point>
          <Point iconType="chat" reverse={true}>
            <strong>Помогает в чате</strong> договориться<br /> о встрече
          </Point>
        </div>

        <div className="main-page__store main-page__store_reverse">
          {Logo}
          <LogoBigIcon />
        </div>

      </div>

      <Footer />
    </Fragment>
  )
};
