import React, { FC } from 'react';

export const Params: FC = () => (
  <div className="faq-page__params">
    <p className="faq-page__text-item-line">
      Четыре  социально-физиологических параметра и  восемь астрологических аспектов.
    </p>
    <ul className="faq-page__text-list">
      <li className="faq-page__text-item-line">
        Возраст
      </li>
      <li className="faq-page__text-item-line">
        Рост
      </li>
      <li className="faq-page__text-item-line">
        Местожительство
      </li>
      <li className="faq-page__text-item-line">
        Рейтинг анкеты
      </li>
    </ul>

    <br /><br />

    <ul className="faq-page__text-list">
      <li className="faq-page__text-item-line">
        Мышление и совместимость на интеллектуальном уровне
      </li>
      <li className="faq-page__text-item-line">
        Сила взаимного влечения партнеров
      </li>
      <li className="faq-page__text-item-line">
        Поведение и согласие в повседневной жизни
      </li>
      <li className="faq-page__text-item-line">
        Сексуальная и физическая совместимость
      </li>
      <li className="faq-page__text-item-line">
        Психологическая совместимость и эмоциональный комфорт
      </li>
      <li className="faq-page__text-item-line">
        Совместимость характеров и темпераментов
      </li>
      <li className="faq-page__text-item-line">
        Процветание, благосостояние и удача
      </li>
      <li className="faq-page__text-item-line">
        Энергетическая совместимость, наследственность
      </li>
    </ul>
  </div>
);
