import React, { FC } from 'react';

export const Cross: FC = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1px" fill="none" fillRule="evenodd" strokeLinecap="square">
      <g transform="translate(1.000000, 1.000000)" stroke="#fff">
        <path d="M0,11 L22,11"></path>
        <path d="M11,0 L11,22"></path>
      </g>
    </g>
  </svg>
)
