import React, { FC, Fragment } from 'react';

import { Desktop, Mobile } from './Subs';

import './AstrologyPage.scss';

export const AstrologyPage: FC = () => {
  return (
    <Fragment>
      <div className="astrology-page main-page main-page_mobile">
        <Mobile />
        <div className="astrology-page__circle astrology-page__circle_mobile"></div>
      </div>

      <div className="astrology-page main-page main-page_desktop">
        <Desktop />
      </div>
    </Fragment>
  )
}