import React, { FC } from 'react';

export const HowMuch: FC = () => (
  <div className="faq-page__how-much">
    <p className="faq-page__text-item-line">
      Мы предлагаем по одному кандидату в день. 
      Если у тебя не специфический запрос (тебе за 50 а ищешь партнера в пределах 20-25 лет) 
      то жди PUSH-уведомление каждое утро. Но если  вы понравились друг другу, то поиск остановится 
      и у вас будет 7 дней для личной встречи, после чего мы снова начнем подбирать тебе кандидатов.
    </p>
  </div>
)