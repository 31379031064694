import React, { FC } from 'react';

import './Circle.scss';

interface Props {
  className?: string;
  count?: number;
  text?: string;
}

export const Circle: FC<Props> = ({ className = '', count, text }) => (
  <div className={`circle ${className}`}>
    {count !== undefined && (
      <p className="circle__number">{count}</p>
    )}
    {text !== undefined && (
      <p className="circle__text">{text}</p>
    )}
  </div>
);
