import React, { FC } from 'react';
import { Footer, Header } from '../../common';

export const OfertaPage: FC = () => (
      <div className="oferta-page docs-page">
            <Header />
            {/* <div className="oferta-page__container docs-page__container">
                  <br /><br /><br />
                  <br />
                  <br />
                  <h2 className="common-page__main-header" style={{ textAlign: 'center' }}>Публичная оферта на оказание платных услуг сервиса</h2>
                  <p><br /></p>
                  <p className="docs-page__text">&nbsp; &nbsp;&nbsp;Настоящий документ является официальным предложением (публичной офертой) <strong><em>Индивидуального предпринимателя Сивожелезова Анатолия Николаевича (ОГРНИП: 316366800139241, ИНН: 890409827529),&nbsp;</em></strong>Администрации Сервиса &laquo;Dr.Svat&raquo;, к Пользователям, согласившимся с условиями настоящей Публичной оферты и начавшим использование Сервиса &laquo;Dr.Svat&raquo;.&nbsp;</p>
                  <p className="docs-page__text">В соответствии со ст. 437 Гражданского Кодекса Российской Федерации (далее по тексту - ГК РФ) данный документ является публичной офертой, и в случае принятия изложенных ниже условий, Пользователь, производящий акцепт данной оферты, одним из способов, указанных в п.&nbsp;1.1.8 Оферты, считается равносильным заключившим Договор на условиях, изложенных в Оферте.</p>
                  <p className="docs-page__text">В связи с этим Пользователю рекомендовано внимательно ознакомится с текстом настоящей Оферты (включая правки, вносимые с течением времени), размещенным на Сайте https://drsvat.com/, https://drsvat.ru/, <a href="http://svodnik.moscow/">http://svodnik.moscow/</a> (далее по тексту &ndash; Сайт), а в случае несогласия с ее условиями - не производить акцепт Оферты.&nbsp;</p>
                  <p className="docs-page__text">Администрация оставляет за собой право изменять условия настоящей Оферты, размещая обновленную версию документа на Сайте. Датой вступления в силу изменений настоящей Оферты является дата их опубликования на Сайте.&nbsp;</p>
                  <p className="docs-page__text">Договор, заключенный посредством акцепта Оферты, не требует двустороннего подписания, считается заключенным с момента его акцепта Пользователем и действителен в электронном виде.</p>
                  <p className="docs-page__text"><br /></p>
                  <p className="docs-page__text"><br /></p>
                  <h2 className="docs-page__header">1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
                  <p><br /></p>
                  <ol>
                        <li className="docs-page__text">Для целей настоящей Оферты нижеприведенные термины и определения используются в следующем значении:&nbsp;</li>
                  </ol>
                  <ul className="docs-page__text">
                        <ol className="docs-page__text">
                              <li><strong>Сайт</strong> - Web-сайт, расположенный в сети Интернет по уникальному адресу (URL) &nbsp;<a href="http://prost-pro-dengi.ru/">https://drsvat.com/</a> (включая поддомены), являющийся совокупностью программно-аппаратных средств и информации, предназначенной для публикации в сети Интернет. Сайт доступен для всех Пользователей сети Интернет.</li>
                              <li><strong>Сервис</strong> &ndash; интернет-сервис &laquo;Dr.Svat&raquo;, представляющий техническую возможность Пользователям получить услуги автоматизированной многоуровневой проверки и подбора наиболее подходящих Пользователю Кандидатов, определяемых из числа других Пользователей, для целей знакомства Пользователя и Кандидата. Доступ к Сервису обеспечивается Пользователем посредством Сайта, установки Мобильного приложения. Сервис является программным продуктом и базой данных, распространяемой Администрацией на территории всего мира. Исключительное право на Сервис принадлежит Администрации.</li>
                              <li><strong>Мобильное приложение, Приложение</strong> &ndash; программное обеспечение &laquo;Доктор Сват&raquo; для портативных (мобильных) устройств, предназначенное для доступа к Сервису, установленное или загружаемое Пользователем на такие устройства с помощью магазина приложений &laquo;Google Play&raquo; для операционной системы Android, в магазине приложений &laquo;AppStore&raquo; для операционной системы &laquo;Apple iOS&raquo; либо через иные ресурсы. Исключительное право на Мобильное приложение принадлежит Администрации.</li>
                              <li><strong>Администрация</strong> &mdash; Индивидуальный Предприниматель Сивожелезов Анатолий Николаевич (ОГРНИП: 316366800139241, ИНН: 890409827529), самостоятельно или с привлечением третьих лиц осуществляющий администрирование Сайта и функционирование Сервиса. Администрация является обладателем исключительных прав на Сервис, имеет правомочия на передачу прав использования и распространения (в порядке и пределах, установленных ниже) Сервиса по настоящей Оферте.</li>
                              <li><strong>Пользователь &ndash;&nbsp;</strong>любое право- и дееспособное физическое лицо, достигшее возраста 18-ти лет, имеющее доступ к Сайту и Мобильному приложению посредством сети Интернет, принявший условия Оферты и использующее Сервис в соответствии с требованиями действующего законодательства и настоящей Оферты.&nbsp;</li>
                              <li><strong>Оферта</strong> &ndash; публичное предложение Администрации, адресованное любому физическому лицу заключить с ним договор присоединения к Сервису на существующих условиях, содержащихся в Оферте.</li>
                              <li><strong>Договор-оферта</strong> - договор между Администрацией и Пользователем, который заключается посредством акцепта оферты.</li>
                              <li><strong>Акцепт&nbsp;</strong>&mdash; полное и безоговорочное принятие Пользователем условий настоящей Оферты, которым является осуществление в совокупности всех нижеперечисленных действий:</li>
                        </ol>
                        <li>ознакомление Пользователя с условиями Оферты;</li>
                        <li>установка Пользователем на своем мобильном устройстве Приложения;</li>
                        <li>заполнение Регистрационной формы;</li>
                        <li>ввод своего логина и пароля в систему авторизации Приложения и авторизованный вход в Приложение.&nbsp;</li>
                        <li>оплата стоимости Тарифа;</li>
                  </ul>
                  <p className="docs-page__text">Пользователь понимает, что Акцепт оферты в порядке, указанном в п. 1.1.9 настоящей Оферты равносилен заключению Договора на условиях, изложенных в Оферте.</p>
                  <ol className="docs-page__text">
                        <li><strong>Услуги</strong> &ndash; консультационные услуги, оказываемые Пользователю в рамках Сервиса. Услуги предоставляются в определенном объеме согласно п. 2.2. Оферты.</li>
                        <li><strong>Устройство</strong> &ndash; мобильное техническое устройство (смартфон, планшет или другое устройство) Пользователя, имеющее доступ к сети Интернет, на котором воспроизводится Сервис.</li>
                        <li><strong>Регистрационная форма</strong> &mdash; форма, заполняемая Пользователем в Приложении для создания Личного кабинета и получения возможности воспользоваться функционалом Сервиса.</li>
                        <li><strong>Личный кабинет</strong> &ndash; совокупность защищенных персонализированных страниц Пользователя, к которой Пользователь получает доступ после прохождения регистрации и/или авторизации в Приложении. Доступ в личный кабинет осуществляется путем ввода аутентификационных данных Пользователя. Личный кабинет предназначен для хранения персональных данных Пользователя, просмотра и управления доступными функциональными возможностями Приложения и соответствующими условиями использования Приложения.</li>
                        <li><strong>Аутентификационные данные</strong> &mdash; уникальный идентификатор (логин) и пароль Пользователя, используемые для доступа в Личный кабинет. В качестве логина используется адрес электронной почты, указанный Пользователем при регистрации в Приложении или телефон. &nbsp;</li>
                        <li><strong>Кандидат&nbsp;</strong>&ndash; Пользователь, разместивший информацию о себе, включая контактную информацию, в сети Интернет с целью знакомства с другими Пользователями, с которым взаимодействует Сервис в автоматическом режиме по заданию Пользователя и/или Пользователь посредством Сервиса.&nbsp;</li>
                        <li><strong>Анкета Кандидата</strong> &ndash; персональные данные другого Пользователя, содержащие изображение этого Пользователя, основные социально-физиологические параметры и его контактные данные. Пользователь получает доступ к Полной Анкете Кандидата, только при получении согласия Кандидата. Во всех остальных случаях Пользователю предоставляются сокращенные Анкеты Кандидатов, без указания контактных данных Кандидата (Сокращенная Анкета кандидата).&nbsp;</li>
                        <li><strong>Знакомство&nbsp;</strong>&ndash; подбор наиболее подходящих Пользователю Кандидатов, определяемых из числа других Пользователей, для целей свидания Пользователя и Кандидата.</li>
                        <li><strong>Свидание&nbsp;</strong>&ndash; функционал Сервиса, позволяющий Пользователям узнавать о симпатиях друг друга, если между ними будет совпадение &mdash; то есть взаимная симпатия. Свидание считается успешным в случае, если Пользователи начали общение через мессенджер WhatsApp и (или) Telegram.</li>
                        <li><strong>Тариф -&nbsp;</strong>стоимость использования конкретных услуг Сервиса, информация о котором указана на Сайте в соответствующем разделе.&nbsp;</li>
                        <li><strong>Куратор&nbsp;</strong>&ndash; менеджер Администрации, который оказывает личное консультирование Пользователя по работе сервиса и (или) личным данным предложенного Кандидата.</li>
                        <li><strong>Персональные данные&nbsp;</strong>&mdash; адрес электронной почты, телефон, имя, фотография и любая информация, относящаяся к прямо или косвенно определяемому, или определенному лицу, позволяющая определить субъекта персональных данных &ndash; Пользователя, добровольно указанная им при заполнении любых форм в Сервисе и оплате Тарифа. Данные хранятся в базе данных Администрации с использованием сервисов и программ, обеспечивающих надежную защиту от несанкционированного доступа третьих лиц,&nbsp;и подлежат использованию исключительно в соответствии с настоящей Офертой и действующим&nbsp;законодательством РФ.</li>
                        <li><strong>Контент и материалы&nbsp;</strong>&mdash; любые размещенные на Сайте или содержащиеся в Рассылке объекты интеллектуальной собственности Администрации в форме текстов, изображений, рисунков, фотографий, графиков, видео, программ, звуков, пользовательских интерфейсов, логотипов, торговых марок, компьютерных кодов, которые являются содержанием Сайта и/или размещены на Сайте с согласия Администрации.</li>
                        <li><strong>Рассылка</strong> &mdash; автоматизированная отправка электронных писем Администрацией на электронный адрес (email) или на номер мобильного телефона Пользователя, в привязанные к этому номеру аккаунты в мессенджерах, а также отправка Push- уведомлений, всплывающих на экране мобильного устройства Пользователя.</li>
                        <li>В случае если в настоящей Оферте используются термины, не указанные в п. 1.1, то они подлежат использованию и толкованию в соответствии с действующим законодательством, и обычаями делового оборота, сложившимися в сети Интернет.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>2. ПРЕДМЕТ ОФЕРТЫ</strong>&nbsp;
                  </ol>
                  <p className="docs-page__text"><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--two">
                        <li>Предметом настоящей оферты является предоставление Пользователям за плату в рамках Тарифа в течение ограниченного промежутка времени услуги по доступу к Сервису.</li>
                        <li>Пользователю доступны следующие Услуги Сервиса:</li>
                        <ul className="docs-page__text">
                              <li>Создание анкеты для поиска наиболее подходящих Кандидатов, получение рекомендаций по размещению фотографий и заполнению сведений о себе для целей поиска потенциальных Кандидатов;</li>
                              <li>Автоматизированная многоуровневая проверка соответствия сведений (ФИО, возраст, семейное положение, регион проживания) предоставленных при регистрации и подлежащим включению в Анкету Пользователя, сведениям, указанным в паспорте Пользователя и (или)Кандидата;</li>
                              <li>Автоматизированный&nbsp;поиск Кандидатов, соответствующих заданным Пользователем критериям;</li>
                              <li>Подбор наиболее подходящих Пользователю Кандидатов, определяемых из числа других Пользователей для целей знакомства Пользователя и Кандидата.</li>
                              <li>получение данных о подходящих Кандидатах с использованием Сервиса;</li>
                              <li>Получение иных рекомендаций в соответствии с назначением Сервиса.</li>
                        </ul>
                        <li>Пользователь вправе использовать Приложение следующими способами:</li>
                        <ul className="docs-page__text">
                              <li>установка на одно устройство Пользователя, путем скачивания из магазина приложений AppStore или Google Play или иного ресурса соответствующую версию приложения для операционной системы, под управлением которой функционирует устройство Пользователя;</li>
                              <li>воспроизведение Приложения на Устройствах Пользователей;&nbsp;</li>
                              <li>использование Приложения по прямому функциональному назначению.&nbsp;</li>
                        </ul>
                        <p className="docs-page__text">Все перечисленные в настоящем пункте действия осуществляются Пользователем самостоятельно. &nbsp;</p>
                        <ol className="docs-page__text">
                              <li>По итогам работы Сервиса Пользователь получает доступ к полным анкетным данным Кандидата при условии взаимного согласия, выраженного как со стороны Пользователя, так и со стороны Кандидата.</li>
                              <li>Услуги оказываются силами и средствами Администрации или привлеченных им третьих лиц по выбору Администрации; при этом Администрация несет ответственность за действия таких третьих лиц.&nbsp;</li>
                              <li>Пользователь вправе осуществлять право на использование Сервиса на всей территории Российской Федерации и за ее пределами.&nbsp;</li>
                              <li>Исключительные права на Сервис принадлежат Администрации.</li>
                              <li>Администрация передает Пользователю неисключительное право использования (неисключительная лицензия) Сервиса на следующих условиях:</li>
                        </ol>
                        <ul className="docs-page__text">
                              <li>на возмездной основе;</li>
                              <li>на территории всего мира;</li>
                              <li>на правах простой (неисключительной) лицензии.</li>
                        </ul>
                        <li>С момента выполнения Пользователем действий, указанных в п. 1.1.8. Оферты, Договор считается заключенным между Пользователем и Администрацией (пункт 3 статьи 438 Гражданского кодекса Российской Федерации).&nbsp;</li>
                        <li>Объем Услуг ограничен условиями Оферты. Администрация не является участником, организатором какой-ибо сделки между Пользователями, покупателем, продавцом, клиентом, работодателем, посредником, агентом, представителем какого-либо Пользователя, выгодоприобретателем или иным заинтересованным лицом в отношении сделок между Пользователями и третьим лицами. Пользователи используют размещённую в Сервисе информацию, чтобы получать услуги на свой страх и риск без прямого или косвенного участия, или контроля со стороны Администрации.</li>
                        <li>Услуги оказываются при наличии технической возможности. Совершая акцепт Оферты, Пользователь уведомлен, что оказываемые услуги являются комплексным электронным Сервисом, который зависит от массы разных факторов, находящихся вне зоны контроля Администрации: работоспособность каналов связи, изменения в правилах и API используемых сторонних серверов, доступность сторонних серверов. Оказание услуги на протяжении 100% времени не гарантируется. Возможны перерывы в предоставлении доступа.</li>
                        <li>В Сервисе запрещена ненормативная лексика, грубое общение, несанкционированная реклама, размещение информации и материалов, негативно влияющих на имидж Сайта.&nbsp;</li>
                        <li>В Сервисе запрещено размещение материалов, содержащих угрозы и оскорбления, дискредитирующих других лиц, нарушающих права граждан на частную жизнь или публичный порядок, носящих характер непристойности; нарушающие в той или иной степени честь и достоинство, права и охраняемые законом интересы других лиц; способствующие или содержащие призывы к разжиганию религиозной, расовой или межнациональной розни, содержащие попытки разжигания вражды или призывы к насилию, а также любых материалов, нарушающих законодательство и авторское право. Всю ответственность в случае нарушений несет Пользователь.&nbsp;</li>
                        <li>Акцепт предполагает, что Пользователь ознакомлен с условиями, порядком оказания и оплаты Услуг, а также признает безусловную пригодность платежной системы, предложенной Администрацией для оплаты Услуг, а также что оказание Администрацией Услуг осуществляется дистанционно с использованием программного обеспечения Администрации и полностью соответствует возможности Пользователя пользоваться Услугами, оказываемыми таким способом.</li>
                        <li>Администрация оставляет за собой право изменять условия Оферты, и, соответственно, настоящего Договора в одностороннем порядке без предварительного согласования с Пользователями. Поэтому Администрация рекомендует Пользователям регулярно проверять условия настоящего Договора на предмет его изменения и/или дополнения. Действующая редакция постоянно размещена на Сайте.</li>
                        <li>Пользователь соглашается, что внесение изменений и дополнений в настоящую Оферту влечет за собой внесение изменений и дополнений в уже заключенный путем Акцепта, и действующий между Сторонами Договор, и они вступают в силу одновременно с такими изменениями в настоящем Договоре. Указанное не относится к стоимости Тарифа (в случае повышения Тарифа доплата к уже действующему у Пользователя Тарифу не производится.</li>
                        <li>Право на использование Приложения распространяется на все последующие обновления (новые версии) Приложения. Соглашаясь с установкой обновления (новой версии) Приложения, Пользователь принимает условия настоящей Оферты для соответствующих обновлений (новых версий) Приложения, если обновление (установка новой версии) Приложения не сопровождается иным соглашением.</li>
                        <li>Под действие настоящей Оферты подпадают все существующие (реально функционирующие) на данный момент функции Сайта и Сервиса, а также любые их последующие модификации и появляющиеся в дальнейшем функции.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>3. ПРАВА И ОБЯЗАННОСТИ СТОРОН</strong>
                  </ol>
                  <ol className="docs-page__text docs-page__list docs-page__list--three">
                        <li>Обязанности Администрации:</li>
                        <ol className="docs-page__text">
                              <li>Предоставить Пользователю после оплаты персональный доступ к Услугам Сервиса в соответствии с условиями настоящей Оферты в объеме согласно Тарифу.</li>
                              <li>Оказывать Услуги с надлежащим качеством и в полном объеме.</li>
                              <li>Решать с помощью переписки спорные ситуации при обращении Пользователя в службу поддержки Сайта. Если какой-либо вопрос не регулируется настоящей Офертой Администрация принимает решение о том, как необходимо поступить в той или иной ситуации.</li>
                              <li>Не разглашать Персональные данные Пользователей и не предоставлять доступ к этой информации третьим лицам за исключением случаев, прямо предусмотренных законодательством и настоящей Офертой.</li>
                              <li>Устанавливать любые ограничения на использование Сервиса;</li>
                              <li>Приложить все разумные усилия по обеспечению стабильной работы Сервиса, постепенному его совершенствованию, исправлению ошибок в работе, однако, Сервис предоставляется в пользование по принципу &laquo;как есть&raquo;. Это означает, что Администрация</li>
                        </ol>
                        <ul className="docs-page__text">
                              <li>не гарантирует отсутствие ошибок в работе Сервиса.</li>
                              <li>не несет ответственность за бесперебойную работу Сервиса и его совместимость с программным обеспечением и техническими средствами Пользователя и иных лиц;</li>
                              <li>не несет ответственности за потерю данных или за причинение любых убытков, которые возникли или могут возникнуть в связи с использованием Сервиса;</li>
                              <li>не несет ответственности за неисполнение либо ненадлежащее исполнение своих обязательств вследствие сбоев в телекоммуникационных и энергетических сетях, действий вредоносных программ, а также недобросовестных действий третьих лиц, направленных на несанкционированный доступ и (или) выведение из строя программного и (или) аппаратного комплекса Сервиса.</li>
                        </ul>
                        <li>Администрация вправе:</li>
                        <ol className="docs-page__text">
                              <li>В одностороннем порядке изменить условия настоящей Оферты;</li>
                              <li>В одностороннем порядке отказать Пользователю в предоставлении доступа к Сервису или отменить доступ Пользователя к Сервису (заблокировать на неопределенное время или удалить личный кабинет Пользователя) в случае существенного нарушения Пользователем условий Оферты.</li>
                        </ol>
                        <p className="docs-page__text">Под существенным нарушением условий Оферты понимается:</p>
                        <ul className="docs-page__text">
                              <li>любое нарушение авторских прав, регламентированных действующим законодательством РФ &laquo;Об авторском праве&raquo;;</li>
                              <li>Однократное нарушение Пользователем п.3.3.4-3.3.9. Оферты;</li>
                              <li>обман Администрации;</li>
                              <li>грубое общение Пользователя с Администрацией, употребле&shy;ние ненормативной лексики, хамство, общие призывы к недоверию либо оскорбление Администрации или других Пользователей;</li>
                              <li>массовая рассылка электронных сообщений коммерческого, рекламного и иного характера, не согласованных получателем по электронной почте через технические ресурсы Администрации;&nbsp;</li>
                              <li>наполнение личного кабинета Пользователя информацией, распространение которой нарушает нормы действующего законодательства РФ, настоящей Оферты, неверной информацией или если у Администрации есть основания полагать, что предоставленная Пользователем информация неполна или недостоверна;</li>
                              <li>если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать пользователя.</li>
                              <li>рассылка &laquo;спама&raquo; через ресурсы, не принадлежащие Администрации, в том случае, если в сообщении указаны координаты (адрес сайта, электронной почты и т.д.), поддерживаемые Администрацией.</li>
                        </ul>
                        <p className="docs-page__text">В случае отказа Администрации от исполнения Оферты по причине существенного нарушения Пользователем&nbsp;условий настоящей Оферты, оплата услуг (стоимости Тарифа) не возвращается.</p>
                        <ol className="docs-page__text">
                              <li>Осуществлять Рассылку Пользователям сообщений, являющихся уведомлениями о введении в действие новых, либо отмене старых услуг Сайта, и уведомлений, содержащих рекламную информацию и других сообщений о событиях, происходящих в рамках Сервиса или в связи с ним.</li>
                              <li>В любое время без предварительного уведомления неограниченное количество раз осуществлять модерацию и изменение Сервиса, его разделов, сервисов, возможностей и инструментов, удалять, изменять и размещать любые результаты интеллектуальной деятельности с предварительным уведомлением или без такового, в том числе вводя дополнительные ограничения в его использовании.</li>
                              <li>Проводить профилактические работы, влекущие приостановление работы Сервиса.&nbsp;</li>
                              <li>Изменять, дорабатывать, добавлять, удалять функции, изменять алгоритмы работы и обновлять Сервис без согласия и уведомления Пользователя, равно как и прекратить его поддержку или полное функционирование, а также осуществлять иные действия в качестве правообладателя Сервиса.</li>
                              <li>Обязанности Пользователя:</li>
                              <li>До момента акцепта Оферты ознакомиться с содержанием настоящей Оферты, соблюдать ее условия.&nbsp;</li>
                              <li>Оплатить стоимость Тарифа в определенном настоящей Офертой порядке.&nbsp;</li>
                              <li>Заполнить Регистрационную форму, соглашаясь с тем, что вся информация Пользователя является точной, достоверной, актуальной и полной. Поддерживать актуальность указанной информации, по запросу Администрации предоставлять иные необходимые материалы и информацию.</li>
                              <li>Предоставить свои Персональных данные для получения Услуг и для оперативной связи с Администрацией и дать согласие Администрации на автоматизированную, а также без использования средств автоматизации обработку своих персональных данных путем сбора, систематизации, накопления, хранения, уточнения и удаления. Обработка внесенных Пользователем при акцепте настоящей оферты персональных данных осуществляется на основании подпунктов 1 и 5 пункта 1 статьи 6 Федерального закона №152-ФЗ от 27 июля 2006 года &laquo;О персональных данных&raquo; (в редакции от 25.07.2011).</li>
                              <li>Нести ответственность за все действия, совершенные под его учетной записью, и с использованием его логина и пароля, а также за все действия, совершенные с его Мобильного устройства, на котором используется Приложение. Пользователь обязуется незамедлительно уведомлять Администрации обо всех случаях нарушения безопасности и несанкционированного доступа к учетной записи. При невыполнении Пользователем данной обязанности Пользователь самостоятельно и в полном объеме несет ответственность за действия, совершенные под его учетной записью. Для проведения проверки учетная запись может подвергнуться технической блокировке.</li>
                              <li>Пользователь не вправе:</li>
                        </ol>
                        <ul className="docs-page__text">
                              <li>продавать, тиражировать, копировать Приложение полностью или частично, сдавать во временное пользование (прокат, аренду).</li>
                              <li>отчуждать Приложение полностью или частично иным образом, в том числе безвозмездно;</li>
                              <li>использовать Приложение для создания и публикации программ для ЭВМ или баз данных аналогичных Приложению, включать в Приложение в какие бы то ни было базы данных и/или программы для ЭВМ, распространять Приложение;</li>
                              <li>осуществлять модификацию, усовершенствование, перевод на другие языки, декомпилирование, дизассемблирование, декодирование, эмуляцию, нарушать целостность, восстанавливать исходный код Приложения или каких-либо его частей;</li>
                              <li>использовать Приложение в составе коммерческих продуктов, для предложения коммерческих продуктов или услуг третьим лицам или в иных коммерческих целях;</li>
                              <li>осуществлять запись, распространять (публиковать, размещать на Интернет-сайтах,&nbsp;&nbsp;копировать, передавать или перепродавать третьим лицам) в коммерческих или некоммерческих целях предоставляемую Администрацией информацию и материалы в рамках настоящей Оферты.</li>
                              <li>использовать Приложение для нарушения прав третьих лиц, а также целей, противоречащих действующему законодательству РФ.</li>
                              <li>совершать любые противоправные действия с использованием Сервиса, в том числе те, которые будут представлять собой уголовное преступление, приведут к административной или гражданской ответственности, или поощрять любые противоправные действия;</li>
                        </ul>
                        <ol className="docs-page__text">
                              <li>Обеспечить нормальное функционирование и доступность электронной почты и/или контактного телефонного номера (своевременно пополнять баланс телефонного номера, следить за возможностью приема звонков аппаратом мобильной связи). Пользователь обязан незамедлительно информировать Администрации о невозможности восстановления работоспособности или доступности контактного телефонного номера и/ или электронной почты. В случае изменения контактных данных и другой значимой информации, незамедлительно уведомить Администрации любым доступным способом.</li>
                              <li>Использовать Сервис только для законных целей и законными способами с учетом законодательства РФ и международных норм.</li>
                              <li>Не влиять своими действиями на деловую репутацию Сервиса.</li>
                              <li>Пользователь вправе:<ol>
                                    <ol>
                                          <li>Пользоваться любыми услугами Сервиса при условии соблюдения законодательства Российской Федерации и условий настоящей Оферты.</li>
                                          <li>Прекратить использование Сервиса в любое время;</li>
                                          <li>Обращаться к Администрации по всем вопросам, связанным с оказанием Услуг, в случае возникновения в работе Сайта и Сервиса проблем технического характера, а также в случае получения сообщений, являющихся несанкционированной рекламной рассылкой, либо содержащих угрозы или файлы с подозрением на вирус.</li>
                                    </ol>
                              </ol>
                              </li>
                        </ol>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>4. ДОСТУП К СЕРВИСУ</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--four">
                        <li>Для того чтобы получить доступ к Сервису Пользователю требуется:
                              <ul>
                                    <li>Установка Пользователем на своем устройстве Приложения.</li>
                                    <li>Регистрация в Приложении путем заполнения регистрационной формы (указания своего номера мобильного телефона или e-mail).</li>
                                    <li>Авторизация в Приложении.</li>
                              </ul>
                        </li>
                        <li>Регистрацию Пользователя в приложении Пользователь осуществляет самостоятельно.&nbsp;</li>
                        <li>По&nbsp;итогам регистрации создается личный кабинет Пользователя, который в любой момент имеет возможность изменить указанную в Личном кабинете информацию или удалить свою учетную запись.</li>
                        <li>Пользователь, предоставляя определённые регистрационные данные, соглашается с тем, что вся эта информация является точной, достоверной, актуальной и полной и подтверждает свое согласие на обработку Администрацией своих персональных данных. Администрация обязуется не сообщать регистрационные данные Пользователя, а также иную информацию, касающуюся его личных данных, лицам, не имеющим отношения к исполнению Договора.&nbsp;</li>
                        <li>В дальнейшем вход в Приложение Пользователем осуществляется каждый раз путем прохождения процедуры авторизации &mdash; введения логина и пароля, в результате автоматической авторизации с использованием технологии cookies.</li>
                        <li>Любые действия, совершенные с использованием логина и пароля Пользователя, считаются совершенными соответствующим Пользователем.</li>
                        <li>Одновременно Пользователь может иметь только один Личный кабинет, привязанный к одному номеру телефона.</li>
                        <li>Пользователь обязан хранить логин и пароль от Личного кабинета вне доступа третьих лиц и своевременно их менять в случае утери или иной необходимости.</li>
                        <li>В случае несанкционированного доступа к логину и паролю, их утраты или раскрытия третьим лицам Пользователь обязан незамедлительно сообщить об этом Администрации путем направления электронного письма с указанного при использовании Сервиса адреса электронной почты.</li>
                        <li>В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан при использовании Сервиса, Пользователь обязан незамедлительно заменить такой адрес на новый и сообщить о данном факте Администрации путем направления электронного письма с нового адреса электронной почты.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>5. ФУНКЦИОНАЛ СЕРВИСА</strong>
                  </ol>
                  <ol className="docs-page__text docs-page__list docs-page__list--five">
                        <li>Для того чтобы получить возможность воспользоваться Услугами, Пользователь самостоятельно знакомится с информацией о функционале Сервиса, об Услугах, доступных в рамках Тарифа, и условиях их предоставления, с информацией о стоимости Тарифа, и о периоде действия Тарифа.&nbsp;</li>
                        <li>С помощью заполнения специальной формы, которая находится на главной странице Приложения, реализуется функционал Сервиса для Пользователей. Сервис доступен Пользователю путем скачивания Приложения по ссылке, полученной в смс сообщении.</li>
                        <li>На Сайте представлены варианты Тарифа, каждый из которых имеет свой период действия и стоимость.</li>
                        <li>После нажатия кнопки &laquo;Оплатить тариф&raquo; Пользователь перенаправляется на страницу оплаты. После проведения Пользователем оплаты и зачисления денежных средств, Договор вступает в силу, Пользователь получает доступ к Сервису на период Тарифа.</li>
                        <li>В течение периода действия Тарифа Пользователь вправе пользоваться функционалом Сервиса, доступным в рамках оплаченного Тарифа. По истечении периода действия Тарифа доступ в Сервис закрывается. При внесении следующего платежа Тариф возобновляется.</li>
                  </ol>
                  <ol className="docs-page__header">
                        <strong>6. ЗАПОЛНЕНИЕ ЛИЧНОГО КАБИНЕТА</strong>
                  </ol>
                  <ol className="docs-page__text docs-page__list docs-page__list--six">
                        <li>Пользователь самостоятельно размещает материалы (фотографии, контактная информация, описание фотографий, другое) при помощи программно-технических средств Сервиса через Личный кабинет.&nbsp;&nbsp;Указанные материалы считаются размещенным Пользователем с момента его публикации и модерации в Сервисе.&nbsp;</li>
                        <li>Ответственность за достоверность информации и исполнение сопутствующих обязательств в полной мере лежит на лицах, поместивших материалы в Сервис. Администрация не несёт ответственность за предоставленную информацию Пользователя. Администрация не осуществляет предварительного контроля за размещаемой Пользователем информацией, однако, когда размещение такой информации противоречит применимому законодательству, Администрация вправе удалить соответствующую информацию без предупреждения.</li>
                        <li>Все материалы публикуются и&nbsp;используются &laquo;как есть&raquo; с&nbsp;сохранением авторской грамматики и&nbsp;пунктуации Пользователя.&nbsp;</li>
                        <li>На сервисе не публикуются материалы, которые содержат:</li>
                  </ol>
                  <ul className="docs-page__text">
                        <li>спам;</li>
                        <li>информацию сексуального и порнографического характера в любом виде (тексты, фотографии и т. д.);</li>
                        <li>шокирующие подробности, происшествия, насилия и т. п.;</li>
                        <li>угрозы, оскорбления или ненормативную лексику;</li>
                        <li>бессмысленный набор символов;</li>
                        <li>призывы к дискриминации по любым признакам (политическим, религиозным, этническим и т. д.);</li>
                        <li>описания экстремистского характера;</li>
                        <li>восхваление террористической деятельности, организованной преступности или групп ненавистников;</li>
                        <li>объявления о купле или продаже огнестрельного оружия и запрещенных или отпускаемых по рецепту препаратов (даже если это разрешено в Вашем регионе);</li>
                        <li>объявления об азартных онлайн играх, коммерческих онлайн играх на реальные деньги или онлайн лотереи;</li>
                        <li>пропаганду наркотиков;</li>
                        <li>призывы к насилию любого рода, суициду, нетерпимости к какому-либо социальному классу или группе, ненависти по религиозному, политическому или иному другому признаку.</li>
                        <li>ругательства и оскорбления, способные обидеть другого человека;</li>
                        <li>призывает к ведению незаконной деятельности, включая терроризм, расовую вражду, либо публикацию подобных материалов, что является уголовным преступлением;</li>
                        <li>является клеветническим;</li>
                        <li>относится к коммерческой деятельности (включая, без ограничений, продажу, рекламу, ссылки на другие сайты или платные телефонные номера);</li>
                        <li>изображает человека, компанию или бренд с целью обмана или введения в замешательство;</li>
                        <li>содержит шпионские программы, рекламные программы, вирусы, поврежденные файлы, программы-черви либо другой вредоносный код, имеющий целью нарушить, повредить, ограничить или прервать работоспособность любого программного обеспечения, оборудования, телекоммуникаций, сетей, серверов; троянских коней, созданных с целью повредить, вмешаться в работу, незаконно перехватить или изъять любую информацию (в том числе личную), будь то посредством Сервиса&nbsp;&nbsp;или без иного способа;</li>
                        <li>сам по себе, либо факт размещения которого нарушает права третьей стороны (включая, без ограничений, интеллектуальные права и конфиденциальность);</li>
                        <li>показывает другого человека, если материалы были создан/распространены без ведома этого лица и без предоставления этому лицу возможности отказаться от его создания или распространения;</li>
                        <li>содержит изображения детей, даже если Пользователь присутствует на фото, или ставит под угрозу их безопасность.</li>
                        <li>Пользователь, размещая материалы в Сервисе, обязуется не затрагивать интересы третьих лиц, загружать только собственные фотографии, соблюдать моральные и этические основы текстовой информации.</li>
                  </ul>
                  <ol className="docs-page__header">
                        <strong>7. ПРЕДОСТАВЛЕНИЕ ИНФОРМАЦИИ ДЛЯ ПОЛУЧЕНИЯ УСЛУГ</strong>
                  </ol>
                  <ol className="docs-page__text docs-page__list docs-page__list--seven">
                        <li>В случае необходимости заполнения каких-либо форм на Сайте Пользователь самостоятельно путем заполнения форм обязан предоставить свои персональные и иные данные. Пользователь в соответствии с Федеральным законом&nbsp;&nbsp;№ 152-ФЗ от 27.07.2006 &laquo;О персональных данных&raquo; дает свое согласие на обработку персональных данных, разрешенных субъектом персональных данных для распространения Администрацией, а также неограниченному кругу лиц как без использования средств автоматизации, так и с их использованием. Обработка и распространение персональных данных осуществляется в соответствии с Политикой конфиденциальности персональных данных, размещенной на Сайте. Администрация, в числе прочего вправе передавать персональные данные Пользователя другим Пользователям если есть обоюдная симпатия для цели Свидания.&nbsp;</li>
                        <li>В ходе регистрации Пользователь обязуется предоставить сведения, характеризующие его (пол, возраст, особенности внешности, наличие/отсутствие вредных привычек, вероисповедание, место постоянного проживания), предоставить минимум одну фотографию на которой сам Пользователь виден чётко и ясно.&nbsp;&nbsp;Пользователь обязуется сообщить свои контактные данные, подлежащие раскрытию другим пользователям &ndash; номер мобильного телефона и адрес электронной почты.</li>
                        <li>При предоставлении фотографий, на которых помимо Пользователя изображены другие лица, Пользователь гарантирует, что им были получены все необходимые разрешения на использование таких изображений.</li>
                        <li>В ходе регистрации Пользователь предоставляет свои пожелания к Кандидату (пол, возраст, наличие детей, место постоянного проживания, вероисповедание, наличие/отсутствие вредных привычек, уровень дохода)</li>
                        <li>Отказ Пользователя от передачи Администрации необходимых сведений или документов, влечет невозможность использования Сервисом. Список необходимых документов может быть изменен Администрацией в одностороннем порядке. Администрация имеет право самостоятельно проводить проверку полноты и достоверности предоставленных Клиентом сведений, в том числе с использованием сторонних сервисов и третьих лиц.</li>
                        <li>Пользователь обязуется поддерживать актуальность введенной при регистрации информации весь период пользования Сервисом и вносить соответствующие изменения при необходимости. Администрация не несет ответственности за невозможность подбора подходящих Пользователю Кандидатов, связанную с недостоверностью или неактуальностью, предоставленной Пользователем информации.</li>
                  </ol>
                  <ol className="docs-page__header">
                        <strong>8. Подбор Кандидатов и взаимодействие Пользователей:</strong>
                  </ol>
                  <ol className="docs-page__text docs-page__list docs-page__list--eight">
                        <li>После регистрации и ввода данных (Анкеты, фотографий и тд.) Пользователю предоставляется доступ к базе кандидатов, и Сервис начинает поиск подходящих Кандидатов.</li>
                        <li>По итогам работы Сервиса Пользователю в зависимости от приобретаемого им тарифа гарантированно предоставляется определенное количество Анкет Кандидатов, подходящих под критерии, обозначенные Пользователем, выразивших согласие на раскрытие своих Анкетных данных для Пользователя для последующего с ним взаимодействия (электронная переписка, звонок, встреча).</li>
                        <li>Пользователь уведомлен и соглашается, что частота предоставления подходящих Пользователю Кандидатов определяется не только Сервисом и оплаченным Тарифом, но и фактическим наличием Кандидатов, удовлетворяющих запросу Пользователя, сформулированному им при регистрации. Администрация не может гарантировать наличие определенного количества новых Кандидатов каждый календарный день, как находящееся за пределами ответственности Администрации. Новый Кандидат предоставляется на рассмотрение Пользователя не чаще 1 (одного) Кандидата за 1 (один) день.&nbsp; &nbsp;</li>
                        <li>&nbsp;&nbsp;После получения нескольких анкет Кандидатов Пользователь может проголосовать, нравится ему Кандидат или нет. Это голосование анонимно. Пользователи узнают о симпатиях друг друга только если между ними будет совпадение &mdash; то есть взаимная симпатия. В этом случае Сервис предоставляет Пользователям возможность начать Свидание, что включает:</li>
                        <li>Выражение Пользователем согласия на предложение раскрыть свои Полные Анкетные данные либо отказ от такого раскрытия (отказ от Свидания) не позднее 5 рабочих дней с момента подбора соответствующего Кандидата.</li>
                        <li>В случае согласия Пользователя на раскрытие Полных Анкетных данных &ndash; создание между Пользователями общего чата в мессенджере WhatsApp и (или) Telegram.</li>
                        <li>Списание Свидания с баланса Тарифа Пользователя.</li>
                        <li>В случае если Пользователь более 5 (пяти) раз не даёт ответа (согласие или отказ) по Кандидатам, предложенным Сервисом, Администрация вправе удалить Анкету такого Пользователя, предварительно уведомив его о нарушении условий Сервиса. При этом услуга считается оказанной, в связи с чем возврат по Тарифу не производится. Вместо удаления Анкеты Пользователя Администрация вправе приостановить процесс поиска Кандидатов для такого Пользователя.</li>
                        <li>В случае, если Пользователям после выражения обоюдной симпатии не удалось начать переписку в WhatsApp и (или) Telegram (начать Свидание), при этом Свидание списано с баланса Тарифа, предусмотрен возврат Свидания на баланс при условии обращения Пользователя к Администрации Сервиса.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>9. ЦЕНА&nbsp;</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--nine">
                        <li>Тарифы указаны на Сайте в разделе &laquo;Тарифы&raquo; по адресу: <a href="https://drsvat.ru/tarify">https://drsvat.ru/tarify</a>.&nbsp;</li>
                        <li>Тарифы различаются по сроку действия, количеству подобранных анкет и количеству Свиданий.</li>
                        <li>Оплата осуществляется путем внесения предоплаты в размере 100% стоимости Тарифа банковской картой (платежных систем Visa, MasterCard, МИР).</li>
                        <li>При осуществлении оплаты Тарифа, Пользователь обязуется следовать платежным инструкциям по порядку и способам оплаты, в том числе и по правилам ввода сообщения и номера коротких текстовых сообщений (SMS), включая пунктуационный порядок, порядок ввода заглавных и строчных букв, цифр и языка ввода.</li>
                        <li>Пользователь самостоятельно несет ответственность за ошибки, которые допущены им при оплате. Администрация не несет ответственности за убытки и иные неблагоприятные последствия, которые могут возникнуть у Пользователя и/или третьих лиц в случае неправильного указания назначения платежа.</li>
                        <li>Датой оплаты считается дата поступления денежных средств на расчётный счёт Администрации.</li>
                        <li>Администрация оставляет за собой право в любой момент изменять стоимость Тарифа, публикации указанных изменений производиться на Сайте.</li>
                        <li>При этом стоимость Тарифа, уже оплаченной Пользователем, не изменяется и перерасчету как в сторону увеличения, так и в сторону уменьшения, не подлежит.&nbsp;</li>
                        <li>Пользователь подтверждает свое согласие с тем, что персональные данные, которые Пользователь предоставляет Администрации при оплате Услуг и/или для возврата денежных средств, могут быть переданы кредитным организациям, участвующим в проведении соответствующих операций.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>10. Политика Возвратов Платежей&nbsp;</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--ten">
                        <li>В случае нарушения Пользователем существенных условий настоящей Оферты возврат за оплату Тарифа не будет осуществлен.</li>
                        <li>В случае досрочного расторжения Договора Администрация по требованию Пользователя рассматривает вопрос о возврате денежных средств, уплаченных за Тариф, если такие требования предъявлены в течение 5 дней после совершения платежа. По окончании указанного срока денежные средства, уплаченные за Тариф, не возвращаются, и считаются фактическими расходами Администрации на оказание услуг.&nbsp;</li>
                        <li>В случае использования Пользователями хотя бы одного Свидания, являющегося частью оплаченных услуг, в течение 5 дней, право Пользователя&nbsp;на компенсацию может быть утрачено.&nbsp;Администрация оставляет за собой право отказать в полном или частичном возврате денежных средств без объяснения причин и рассматривает это в частном порядке по письменному заявлению от Пользователя, к примеру в случае, указанном в п. 5.8.6. Оферты.&nbsp;</li>
                        <li>Отказ Пользователя и (или) Кандидата от взаимодействия после дачи согласия на раскрытие своих данных не даёт основания для возврата Тарифа и аннулирования списания Свидания с Тарифа. &nbsp;</li>
                        <li>Все возвраты денежных средств осуществляются при условии направления Пользователем в адрес Администрации требования за личной подписью Пользователя, который просит осуществить возврат денежных средств. Требование необходимо прислать в отсканированном виде на электронную почту&nbsp;Администрации.</li>
                        <li>В требовании о возврате уплаченных за Услугу денежных средств должно быть указано:</li>
                  </ol>
                  <ul className="docs-page__text">
                        <li>данные заявителя (ФИО, паспортные данные, адрес проживания, телефон, адрес электронной почты);</li>
                        <li>документ, подтверждающий оплату с датой и суммой платежа;</li>
                        <li>причины возврата;</li>
                        <li>реквизиты для перевода денежных средств &ndash; счёта, с которого производилась оплата;</li>
                        <li>приложения, дата, подпись и расшифровка.</li>
                  </ul>
                  <ol className="docs-page__text">
                        <li>Решение о возврате или об отказе в возврате денежных средств принимается Администрацией в течение 7 (семи) календарных дней с момента получения заявления.</li>
                        <li>Денежные средства подлежат возврату Пользователю банковским переводом по реквизитам, указанным Пользователем в заявлении в течение 30 (десяти) календарных дней после принятия решения о возврате..&nbsp;</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>11. ОТВЕТСТВЕННОСТЬ СТОРОН</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--eleven">
                        <li>За неисполнение условий, предусмотренных настоящей Офертой, Стороны несут ответственность в соответствии с нормами законодательства, действующего на территории Российской Федерации.</li>
                        <li>Администрация не несет ответственности за невозможность оказания услуг и использования платного функционала Сервиса Пользователями по причинам, не зависящим от Администрации, а именно:&nbsp;</li>
                        <ul className="docs-page__text">
                              <li>нарушение или отсутствие Интернет-соединения со стороны Пользователя;&nbsp;</li>
                              <li>надлежащее функционирование Сайта и Сервиса в случае, если Пользователь не имеет необходимых технических средств для его использования;</li>
                              <li>задержки или сбои в процессе совершения платежных операций;</li>
                              <li>любой случай неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах;</li>
                        </ul>
                        <li>В течение срока действия настоящей Оферты Администрация предпримет все усилия для устранения технических сбоев и ошибок, в случае их возникновения при использовании Пользователем Сервиса. При этом Администрация не гарантирует полного отсутствия технических ошибок и сбоев по причинам, вызванным неисправностью оборудования, ошибками разработчиков Сервиса.&nbsp;</li>
                        <li>В случае если технический сбой или программная ошибка, входящие в компетенцию Администрации, делают полностью невозможным пользование Сервиса, период предоставления Услуг продлевается на срок устранения неполадок. Если невозможность пользования Сервиса сохраняется, а программная ошибка не может быть устранена оперативно, Администрация сообщает Пользователю планируемое время устранения ошибки и, по запросу Пользователя, рекомендует ему альтернативный сценарий решения его задачи на время устранения ошибки.&nbsp;</li>
                        <li>Администрация не несет ответственности за несоответствие Услуг необоснованным ожиданиям Пользователей и их субъективной оценке. Никакая информация, материалы и/или консультации, предоставляемые Администрацией в рамках оказания услуг по настоящему договору, не могут рассматриваться как гарантии. Принятие решений на основе всей предоставленной Администрацией информации находится в исключительной компетенции Пользователя. Пользователь принимает на себя полную ответственность и риски, связанные с использованием информации и материалов, предоставленных Администрацией в рамках исполнения своих обязательств по настоящему Договору.</li>
                        <li>Пользователь несет всю ответственность за содержание сообщений, направляемых&nbsp;Кандидатам, а также действия, совершаемые с полученной информацией и материалами о Кандидатах, и их соответствие требованиям применимого права.</li>
                        <li>Пользователь несет исключительную ответственность за любое нарушение обязательств, установленных настоящей Офертой и/или применимым правом, а также за все последствия таких нарушений (включая любые убытки или ущерб, которые может понести Кандидат, Администрация и иные третьи лица).</li>
                        <li>В случае возникновения каких-либо претензий третьих лиц в отношении нарушения Пользователем любых имущественных и/или личных неимущественных прав третьих лиц, а равно установленных законодательством запретов или ограничений Пользователь обязан по требованию Администрации пройти официальную идентификацию, предоставив Администрации нотариально заверенное обязательство урегулировать возникшие претензии собственными силами и за свой счет с указанием своих паспортных данных.</li>
                        <li>Администрация не несет ответственность за убытки (как реальный ущерб, так и упущенную выгоду, а также любые иные убытки, в том числе нематериальные, вред чести, достоинству, деловой репутации) Пользователя.</li>
                        <li>В случае привлечения Администрации к ответственности или наложения на нее взыскания в связи с допущенными вами нарушениями прав и/или интересов третьих лиц, а равно установленных законодательством запретов или ограничений, Пользователь обязан в полном объеме возместить убытки Администрации.</li>
                        <li>В случае нарушения Пользователями исключительных прав Администрации Администрация вправе отказать Пользователю в оказании Услуг, а также предъявить требование об уплате компенсации в размере 100 000 (Сто тысяч) рублей за каждый случай нарушения, а также компенсации всех причиненных убытков, включая упущенную выгоду.&nbsp;</li>
                        <li>Гиперссылки на любой сайт, канал, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенные на Сайте, не являются одобрением или рекомендацией данных продуктов (услуг) со стороны Администрации. Администрация не несет ответственности за ущерб, причиненный Пользователю в результате перехода по таким гиперссылкам.</li>
                        <li>Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств вследствие обстоятельств непреодолимой силы. Под обстоятельствами непреодолимой силы понимаются: цунами, шторм, землетрясение, наводнения, природные пожары, тайфун, ураганы, засуха, изменение санитарно-эпидемиологической обстановки, катастрофы, массовые выступления, решения государственных органов, а также иные обстоятельства, возникшие в результате событий чрезвычайного характера. Сторона, попавшая под действие непреодолимой силы, при первой возможности, но не позднее трех дней, уведомляет другую сторону в письменном виде о случившемся.&nbsp;</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>12. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ.</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--twelve">
                        <li>Сайт и Сервис являются интеллектуальной собственностью Администрации, правовая охрана которой устанавливается Гражданским Кодексом Российской Федерации, международными договорами Российской Федерации. Администрация гарантирует&nbsp;наличие у нее прав, необходимых для исполнения настоящего Договора, при этом&nbsp;Администрация информирует Пользователя, что согласно ст. 1262 Гражданского кодекса Российской Федерации государственная регистрация прав в отношении баз данных осуществляется по желанию правообладателя.</li>
                        <li>Все исключительные права на контент и материалы, размещенные на Сайте и в Сервисе Администрацией в форме текстов, изображений, рисунков, фотографий, графиков, видео, программ, звуков, интерфейсов, логотипов, торговых марок, компьютерных кодов принадлежат Администрации и являются объектами ее интеллектуальной собственности.</li>
                        <li>Никакие права на любое содержимое Сайта, в котором хранятся Контент и материалы Администрации, включая, помимо прочего, аудиовизуальные произведения, текстовые и графические материалы, программы для ЭВМ, товарные знаки не переходят к Пользователю в результате использования Сайтом, и заключения настоящего Договора.&nbsp;</li>
                        <li>Пользователь имеет право пользоваться Сайтом и Сервисом исключительно для достижения цели Договора.&nbsp;</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>13. СРОК ДЕЙСТВИЯ ОФЕРТЫ</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--thirteen">
                        <li>Срок для совершения Акцепта оферты ограничен нахождением оферты на Сайте.</li>
                        <li>Администрация вправе в любой момент отказаться от исполнения настоящей Оферты полностью в случае существенного нарушения Пользователем условий настоящей Оферты. Оферта считается расторгнутой с момента направления уведомления Администрации Пользователю. В данном случае доступ в личный кабинет Пользователя блокируется. При этом Услуги считаются оказанными Пользователю в полном объеме, а внесенная предоплата за пользование Сервисом не возвращается.</li>
                        <li>Срок Оферты не является сроком оказания услуг согласно Тарифу.&nbsp;</li>
                        <li>Настоящий Договор-оферта вступает в силу с момента совершения акцепта и действует до полного исполнения Сторонами своих обязательств.</li>
                        <li>Признание судом недействительности какого-либо положения настоящего Договора-оферты не влечет за собой недействительность остальных положений.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__header">
                        <strong>14. ИНЫЕ ПОЛОЖЕНИЯ</strong>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text docs-page__list docs-page__list--fourteen">
                        <li>Все разногласия, возникшие при исполнении Оферты, в связи с ним или в результате его исполнения Стороны разрешают путем переговоров. Претензионный порядок обязателен. Срок ответа на претензию - 30 календарных дней.&nbsp;</li>
                        <li>В случае не достижения Сторонами соглашения, спор передается на рассмотрение в суд по месту нахождения Администрации (договорная подсудность).</li>
                        <li>По вопросам, не урегулированным настоящей Офертой, подлежат применению законы и иные правовые акты Российской Федерации, в том числе соответствующие правовые акты, принятые субъектами Российской Федерации и органами местного самоуправления.</li>
                        <li>Стороны условились применять к подписанию документов, составляющих содержание электронной переписки Сторон, правила о простой электронной подписи, рассматривая в качестве такой простой электронной подписи адреса:</li>
                  </ol>
                  <p className="docs-page__text">-для Администрации:&nbsp;<a href="mailto:info@drsvat.ru">info@drsvat.ru</a></p>
                  <p className="docs-page__text">- для Пользователя: электронный адрес, указанный при заполнении любой формы на Сайте или указанный Пользователем в переписке. &nbsp;</p>
                  <ol className="docs-page__text">
                        <li>Стороны договорились приравнивать такие простые электронные подписи к аналогу собственноручной подписи Сторон, а документы в электронной форме &ndash; к аналогам документов на бумажном носителе.&nbsp;</li>
                        <li>Стороны признают, что все уведомления, сообщения, соглашения, документы и письма, направленные с использованием уполномоченных адресов электронной почты, могут быть использованы в качестве письменных доказательств в суде.</li>
                  </ol>
                  <p><br /></p>
                  <ol className="docs-page__text">
                        <strong>15. РЕКВИЗИТЫ АДМИНИСТРАЦИИ</strong>
                  </ol>
                  <p><br /></p>
                  <p className="docs-page__text"><strong><em>Индивидуальный Предприниматель Сивожелезов Анатолий Николаевич</em></strong></p>
                  <p className="docs-page__text">ОГРНИП:&nbsp;316366800139241</p>
                  <p className="docs-page__text">ИНН:&nbsp;890409827529</p>
                  <p className="docs-page__text">Банковские реквизиты:</p>
                  <p className="docs-page__text">Расчетный счет 40802810800002849718</p>
                  <p className="docs-page__text">Банк АО &quot;ТИНЬКОФФ БАНК&quot;</p>
                  <p className="docs-page__text">БИК 044525974</p>
                  <p className="docs-page__text">Корсчет 30101810145250000974</p>
                  <p className="docs-page__text">E-mail: <a href="mailto:info@drsvat.ru">info@drsvat.ru</a></p>
                  <p><br /></p>
                  <p><br /></p>
                  <p><br /></p>
                  <p><br /></p>
                  <p><br /></p>    </div> */}
            <Footer />
      </div >
);
