import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import './BonusPoint.scss';

interface Props {
  number: string;
  icon: string;
  title: string;
  image: string;
  className?: string;
  reverse?: boolean;
};

export const BonusPoint: FC<Props> = ({ number, icon, title, image, children, className = '', reverse = false }) => {

  const topClassNames = useMemo(() => cn('bonus-page-point__top', { 'bonus-page-point__top_reverse': reverse }), [reverse]);

  return (
    <div className={`bonus-page-point ${className}`}>

      <div className={topClassNames}>
        <div className="bonus-page-point__head">
          <p className="bonus-page-point__number">
            {number}
            <img className="bonus-page-point__number-icon" src={icon} alt={title} />
          </p>

          <p className="bonus-page-point__title">
            {title}
          </p>

          <div className="common-page__delimiter bonus-page-point__delimiter"></div>

          <p className="bonus-page-point__text bonus-page-point__text_sl">
            {children}
          </p>

        </div>

        <img className="bonus-page-point__image" src={image} alt={title} />
      </div>

      <p className="bonus-page-point__text">
        {children}
      </p>

    </div>
  )
};