import React, { FC, useCallback } from 'react';

import './PlayStoreIcon.scss';

interface Props {
  isMobile?: boolean;
}

export const PlayStoreIcon: FC<Props> = ({ isMobile = true }) => {

  const handleStoreClick = useCallback(() => (window as any)?.ym(86361007, 'reachGoal', 'klik_na_magazin'), []);

  return isMobile ? (
    <a onClick={handleStoreClick} href="https://redirect.appmetrica.yandex.com/serve/963872057915958535" target="_blank" rel="noreferrer">
      <div className="play-store-logo" />
    </a>
  ) : (
    <div className="play-store-logo" />
  )
};
