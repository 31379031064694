import React, { FC } from 'react';

import { Chip } from '../../models';
import { AccordionItem } from './Subs';

import './Accordion.scss';

interface Props {
  chips: Chip[];
  className?: string;
}

export const Accordion: FC<Props> = ({ chips, className = '' }) => (
  <ul className={`accordion ${className}`}>
    {chips.map((chip, index) => (
      <AccordionItem key={index} chip={chip} />
    ))}
  </ul>
);
