import React, { FC } from 'react';

export const SunIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M7.74553 7.74604C8.8249 6.66667 10.3487 5.96826 11.9995 5.96826C13.6503 5.96826 15.1741 6.63493 16.2535 7.74604C17.3328 8.8254 18.0312 10.3492 18.0312 12C18.0312 13.6508 17.3328 15.1746 16.2535 16.254C15.1741 17.3333 13.6503 18.0318 11.9995 18.0318C10.3487 18.0318 8.8249 17.3651 7.74553 16.254C6.66617 15.1746 5.96776 13.6508 5.96776 12C5.96776 10.3492 6.63442 8.8254 7.74553 7.74604ZM8.88839 15.1111C9.68204 15.9048 10.7932 16.381 11.9995 16.381C13.2059 16.381 14.317 15.9048 15.1106 15.1111C15.9043 14.3175 16.3805 13.2064 16.3805 12C16.3805 10.7937 15.9043 9.68255 15.1106 8.8889C14.317 8.09525 13.2059 7.61906 11.9995 7.61906C10.7932 7.61906 9.68204 8.09525 8.88839 8.8889C8.09474 9.68255 7.61855 10.7937 7.61855 12C7.61855 13.2064 8.09474 14.3175 8.88839 15.1111Z" fill="#F6193D" />
      <path d="M0.825871 11.1748H3.27031C3.71475 11.1748 4.0957 11.5558 4.0957 12.0002C4.0957 12.4446 3.71475 12.8256 3.27031 12.8256H0.825871C0.381428 12.8256 0.000476837 12.4446 0.000476837 12.0002C0.000476837 11.5558 0.381428 11.1748 0.825871 11.1748Z" fill="#F6193D" />
      <path d="M12.0008 19.9048C12.4452 19.9048 12.8262 20.2857 12.8262 20.7302V23.1746C12.8262 23.6191 12.4452 24 12.0008 24C11.5563 24 11.1754 23.6191 11.1754 23.1746V20.7302C11.1754 20.2857 11.5563 19.9048 12.0008 19.9048Z" fill="#F6193D" />
      <path d="M3.52341 19.3332L5.26944 17.5872C5.55515 17.2698 6.09484 17.2698 6.4123 17.5872C6.72976 17.9047 6.72976 18.4126 6.4123 18.7301L4.66626 20.4761C4.3488 20.7936 3.84087 20.7936 3.52341 20.4761C3.20595 20.1586 3.20595 19.6507 3.52341 19.3332Z" fill="#F6193D" />
      <path d="M12.0008 0C12.4452 0 12.8262 0.380952 12.8262 0.825396V3.26984C12.8262 3.71428 12.4452 4.09523 12.0008 4.09523C11.5563 4.09523 11.1754 3.71428 11.1754 3.26984V0.825396C11.1754 0.380952 11.5563 0 12.0008 0Z" fill="#F6193D" />
      <path d="M3.49216 3.52374C3.80962 3.20628 4.31755 3.20628 4.63501 3.52374L6.38105 5.26977C6.69851 5.58723 6.69851 6.09517 6.38105 6.41263C6.09533 6.73009 5.55565 6.73009 5.23819 6.41263L3.49216 4.6666C3.1747 4.34914 3.1747 3.8412 3.49216 3.52374Z" fill="#F6193D" />
      <path d="M20.7302 11.1748H23.1746C23.619 11.1748 24 11.5558 24 12.0002C24 12.4446 23.6508 12.8256 23.1746 12.8256H20.7302C20.2857 12.8256 19.9048 12.4446 19.9048 12.0002C19.9048 11.5558 20.2857 11.1748 20.7302 11.1748Z" fill="#F6193D" />
      <path d="M17.6191 17.5872C17.9048 17.2698 18.4445 17.2698 18.762 17.5872L20.508 19.3332C20.8255 19.6507 20.8255 20.1586 20.508 20.4761C20.1905 20.7936 19.6826 20.7936 19.3651 20.4761L17.6191 18.7301C17.3016 18.4126 17.3016 17.9047 17.6191 17.5872Z" fill="#F6193D" />
      <path d="M17.6191 5.26977L19.3651 3.52374C19.6826 3.20628 20.1905 3.20628 20.508 3.52374C20.8255 3.8412 20.8255 4.34914 20.508 4.6666L18.762 6.41263C18.4445 6.73009 17.9366 6.73009 17.6191 6.41263C17.3016 6.09517 17.3016 5.58723 17.6191 5.26977Z" fill="#F6193D" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
      </clipPath>
    </defs>
  </svg>
);
