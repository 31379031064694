import React, { FC, Fragment } from 'react';

import { Desktop, Mobile } from './Subs';

import './MainPage.scss';
import { Pulse } from '../../common';

export const MainPage: FC = () => {
  return (
    <Fragment>
      <div className="main-page main-page_mobile">
        <Mobile />
        <Pulse className="main-page__pulse"/>
      </div>

      <div className="main-page main-page_desktop">
        <Desktop />
      </div>
    </Fragment>
  )
}