import React from 'react';

import { AstrologyPage, BonusPage, CompanyPage, FAQPage, GeographyPage, MainPage, OfertaPage, PolicyPage } from './pages';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';
import { ScrollTop } from './common';

const App = () => (
  <BrowserRouter>
    <ScrollTop />
    <Switch>
      <Route path="/astrology" component={AstrologyPage} />
      {/* <Route path="/rates" component={RatesPage} /> */}
      <Route path="/bonus" component={BonusPage} />
      <Route path="/privacy" component={PolicyPage} />
      <Route path="/terms" component={OfertaPage} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/geography" component={GeographyPage} />
      <Route path="/company" component={CompanyPage} />
      <Route path="/" component={MainPage} />
    </Switch>
  </BrowserRouter>
);

export default App;
