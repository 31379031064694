import React, { FC } from 'react';

import './LogoBigIcon.scss';

interface Props {
  className?: string;
}

export const LogoBigIcon: FC<Props> = ({ className = '' }) => (
  <div className={`logo-big-icon ${className}`}>
    <div className="logo-big-icon__container">
      <svg width="100%" height="100%" viewBox="0 0 72 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.0719 13.5818L36 20.3107L47.9281 13.5818L71.7031 0.203125V27.0396V53.7969L47.9281 40.4182L36 33.6893L24.0719 40.4182L0.296867 53.7969V27.0396V0.203125L24.0719 13.5818Z" fill="#FFFFFF" />
      </svg>
    </div>
  </div>
)
