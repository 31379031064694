import React, { FC } from 'react';

export const NoResult: FC = () => (
  <div className="faq-page__no-result">
    <p className="faq-page__text-item-line">
      Бывает и так. Значит сегодня у нас нет в базе свободного, 
      а главное подходящего для тебя кандидата. Завтра, с утра, будут созданы новые пары и возможно 
      там, будет кандидат и для тебя. В любом случае не надо отчаиваться, наша база постоянно растет - 
      как только подходящий кандидат появится - мы вас оповестим.
    </p>
  </div>
)