import React, { FC } from 'react';
import { Chat } from '../Chat';

import { CalendarIcon, GalaxyIcon, HeartIcon, PersonIcon, SaturnIcon, SexesIcon, ShieldIcon, SunIcon } from './Subs';

interface Props {
  type: 'shield' | 'calendar' | 'person' | 'heart' | 'sexes' | 'sun' | 'galaxy' | 'saturn' | 'chat';
}

export const PointIcon: FC<Props> = ({ type }) => {
  switch (type) {
    case 'shield':

      return <ShieldIcon />;

    case 'calendar':

      return <CalendarIcon />;

    case 'heart':

      return <HeartIcon />;

    case 'person':

      return <PersonIcon />;

    case 'sexes':

      return <SexesIcon />;

    case 'sun':

      return <SunIcon />;

    case 'galaxy':

      return <GalaxyIcon />;

    case 'saturn':

      return <SaturnIcon />;

    case 'chat':

      return <Chat />;

    default:

      return <ShieldIcon />;
  }
}