import React, { FC } from 'react';
import { Footer, Header } from '../../common';

export const PolicyPage: FC = () => (
  <div className="policy-page docs-page">
    <Header />
    <div className="policy-page__container docs-page__container">
      <br /><br /><br />
      <br />
      <br />
      <h2 className="common-page__main-header">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
      <br /><br /><br />
      <p className="docs-page__text">Настоящее соглашение определяет Политику Индивидуальный Предприниматель Сивожелезов Анатолий Николаевич (ОГРНИП 316366800139241) (далее - &laquo;Правообладатель&raquo;) в отношении обработки персональных данных и реализации требований к защите персональных данных (далее &ndash;&laquo;Политика&raquo;) в соответствии с требованиями ст. 18.1. Федерального закона от 27.07.2006 № 152-ФЗ &laquo;О персональных данных&raquo;.</p>
      <br /><br /><br />
      <h2 className="docs-page__header">1. Определение терминов</h2>
            <br /><br />
      <p className="docs-page__text">1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
      <p className="docs-page__text">1.1.1. Сервис &ndash; сервис автоматизированной многоуровневой проверки и подбора наиболее подходящих Пользователю Кандидатов.</p>
      <p className="docs-page__text">1.1.2. &laquo;Администрация сервиса (далее &ndash; Администрация сайта)&raquo; &ndash; уполномоченные сотрудники на управление всех существующих на данный момент сервисов, действующие от имени Правообладателя, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
      <p className="docs-page__text">1.1.3. &laquo;Персональные данные&raquo; - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
      <p className="docs-page__text">1.1.4. &laquo;Обработка персональных данных&raquo; - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
      <p className="docs-page__text">1.1.5. &laquo;Конфиденциальность персональных данных&raquo; - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
      <p className="docs-page__text">1.1.6. &laquo;Платформа&raquo; - программно-аппаратные средства Правообладателя, интегрированные с сайтом и (или) мобильным приложением.</p>
      <p className="docs-page__text">1.1.7. Пользователь Сервиса Правообладателя и всех существующих на данный момент сервисов (далее&nbsp; Пользователь)&raquo; &ndash; лицо, имеющее доступ к Сайту drsvat.ru, мобильному приложению &laquo;Доктор Сват&raquo;, посредством сети Интернет.</p>
      <p className="docs-page__text">1.1.8. &laquo;Cookies&raquo; &mdash; небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
      <p className="docs-page__text">1.1.9. &laquo;IP-адрес&raquo; &mdash; уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
      <h2 className="docs-page__header">2. Общие положения</h2>
            <br /><br />
      <p className="docs-page__text">2.1. Использование Пользователем сервиса означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
      <p className="docs-page__text">2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта drsvat.ru а так же всех существующих на данный момент сервисов Правообладателя</p>
      <p className="docs-page__text">2.3. Настоящая Политика конфиденциальности применяется только к программно-аппаратным средствам Правообладателя интегрированных с сайтом drsvat.ru и (или) мобильным приложениям, Правообладатель не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте drsvat.ru</p>
      <p className="docs-page__text">2.4. Администрация сайта проверяет достоверность персональных данных, предоставляемых Пользователем сервиса drsvat.ru, путем контакта с Пользователем посредством телефонного звонка.</p>
      <h2 className="docs-page__header">3. Предмет политики конфиденциальности&nbsp</h2>
            <br /><br />
      <p className="docs-page__text">3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта&nbsp; по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте drsvat.ru&nbsp; или при оформлении заявки для приобретения услуг Правообладателя.</p>
      <p className="docs-page__text">3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте drsvat.ru в мобильном приложении &laquo;Доктор Сват&raquo; и включают в себя следующую информацию:</p>
      <p className="docs-page__text">3.2.1. имя;</p>
      <p className="docs-page__text">3.2.2. контактный телефон Пользователя;</p>
      <p className="docs-page__text">3.2.3. адрес электронной почты (e-mail);</p>
      <p className="docs-page__text">3.2.3.&nbsp; возраст;&nbsp;</p>
      <p className="docs-page__text">3.2.4.&nbsp; дата и время рождения;</p>
      <p className="docs-page__text">3.2.5.&nbsp; семейное положение;&nbsp;</p>
      <p className="docs-page__text">3.2.6.&nbsp; регион проживания;&nbsp;</p>
      <p className="docs-page__text">3.3. Сайт drsvat.ru защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):</p>
      <p className="docs-page__text">IP адрес;</p>
      <p className="docs-page__text">информация из cookies;</p>
      <p className="docs-page__text">информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</p>
      <p className="docs-page__text">время доступа;</p>
      <p className="docs-page__text">адрес страницы, на которой расположен рекламный блок;</p>
      <p className="docs-page__text">реферер (адрес предыдущей страницы).</p>
      <p className="docs-page__text">3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта drsvat.ru.</p>
      <p className="docs-page__text">3.3.2. Сайт drsvat.ru осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.</p>
      <p className="docs-page__text">3.4. Любая иная персональная информация не оговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>
      <h2 className="docs-page__header">4. Цели сбора персональной информации пользователя</h2>
            <br /><br />
      <p className="docs-page__text">4.1. Персональные данные Пользователя Администрация сайта drsvat.com может использовать в целях:</p>
      <p className="docs-page__text">4.1.1. Идентификации Пользователя, зарегистрированного на сайте drsvat.com, для оформления заказа.</p>
      <p className="docs-page__text">4.1.2. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта drsvat.com, оказания услуг, обработка запросов и заявок от Пользователя.</p>
      <p className="docs-page__text">4.1.3. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
      <p className="docs-page__text">4.1.4. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.</p>
      <p className="docs-page__text">4.1.5. Уведомления Пользователя Сайта drsvat.com&nbsp; о состоянии Поиска Кандидатов.</p>
      <p className="docs-page__text">4.1.6. Обработки и получения платежей Пользователя.</p>
      <p className="docs-page__text">4.1.7. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта drsvat.com.</p>
      <p className="docs-page__text">4.1.8. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Агентства знакомств &laquo;Dr.Svat&raquo;.</p>
      <p className="docs-page__text">4.1.9. Осуществления рекламной деятельности с согласия Пользователя.</p>
      <p className="docs-page__text">4.1.10. Публиковать контактные данные Пользователя Кандидатам, при условии взаимного согласия выраженного как со стороны Пользователя, так и со стороны Кандидата.</p>
      <h2 className="docs-page__header">5. Способы и сроки обработки персональной информации</h2>
            <br /><br />
      <p className="docs-page__text">5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</p>
      <p className="docs-page__text">5.2 Пользователь вправе отозвать согласие на обработку персональных данных путем подачи на сайт drsvat.ru заявки через обратную связь, или письменно на почту <a href="mailto:info@drsvat.ru" target="_blank" rel="noopener">info@drsvat.ru</a>.</p>
      <p className="docs-page__text">5.3. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные Кандидату или же третьим лицам, только с согласия Пользователя.</p>
      <p className="docs-page__text">5.4. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
      <h2 className="docs-page__header">6. Обязательства сторон</h2>
            <br /><br />
      <p className="docs-page__text">6.1. Пользователь обязан:</p>
      <p className="docs-page__text">6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом drsvat.com и мобильными приложениями &laquo;Доктор Сват&raquo;</p>
      <p className="docs-page__text">6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
      <p className="docs-page__text">6.2. Администрация сайта обязана:</p>
      <p className="docs-page__text">6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
      <p className="docs-page__text">6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.3. настоящей Политики Конфиденциальности.</p>
      <p className="docs-page__text">6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
      <h2 className="docs-page__header">7. Ответственность сторон</h2>
            <br /><br />
      <p className="docs-page__text">7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.</p>
      <p className="docs-page__text">7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>
      <p className="docs-page__text">7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
      <p className="docs-page__text">7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
      <p className="docs-page__text">7.2.3. Была разглашена с согласия Пользователя.</p>
      <h2 className="docs-page__header">8. Разрешение споров</h2>
            <br /><br />
      <p className="docs-page__text">8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта drsvat.ru&nbsp; и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
      <p className="docs-page__text">8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
      <p className="docs-page__text">8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.</p>
      <p className="docs-page__text">8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</p>
      <h2 className="docs-page__header">9. Дополнительные условия</h2>
            <br /><br />
      <p className="docs-page__text">9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
      <p className="docs-page__text">9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте drsvat.com, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>    </div>
    <Footer />
  </div>
);
