import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { Cross } from '../../../icons';
import { Chip } from '../../../models';

interface Props {
  chip: Chip;
}

export const AccordionItem: FC<Props> = ({ chip }) => {

  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const classNames = useMemo(() => cn('accordion__item', { 'accordion__item_open': isOpen }), [isOpen]);

  return (
    <li className={classNames} onClick={handleItemClick}>
      <div className="accordion__header-container">
        <p className="accordion__title">{chip.title}</p>
        <button className="accordion__btn">
          <Cross />
        </button>
      </div>
      {chip.text !== undefined && (
        <Fragment>
          <p className="accordion__text" dangerouslySetInnerHTML={{ __html: chip.text }} />
        </Fragment>
      )}

      {chip.element !== undefined && <div className="accordion__content">{chip.element({})}</div>}
    </li>
  );
};