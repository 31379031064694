import React, { FC } from 'react';

import { LogoIcon, LogoTextIcon } from './Subs';

import './MainLogo.scss';

export const MainLogo: FC = () => (
  <div className="main-logo">
    <LogoIcon />
    <LogoTextIcon />
  </div>
)
