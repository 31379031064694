import React, { FC } from 'react';

import './CityBlock.scss';

export const CityBlock: FC = () => (
  <div className="city-block">
    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Москва
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Ростов-на-Дону
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Санкт-Петербург
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Уфа
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Новосибирск
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Красноярск
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Екатеринбург
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Пермь
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Нижний Новгород
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Воронеж
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Казань
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Волгоград
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Челябинск
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Краснодар
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Омск
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Саратов
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Самара
        </p>
      </div>
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Тюмень
        </p>
      </div>
    </div>

    <div className="city-block__line">
      <div className="city-block__item">
        <div className="city-block__item-circle  city-block__item-circle_active"></div>
        <p className="city-block__item-text">
          Сочи
        </p>
      </div>
    </div>
  </div>
);
