import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import { Portal } from '../Portal';
import { isTextModalVisible, toggleTextModal } from '../../../store';

import './TextModal.scss';

export const TextModal: FC = ({ children }) => {
  const isModalShown = useStore(isTextModalVisible);

  const closeModal = useCallback(() => toggleTextModal(), [])

  return isModalShown ? (
    <Portal>
      <div className="modal__container">
        <div className="modal">
          <p className="modal__text">{children}</p>
          <button className="modal__close-btn" onClick={closeModal}/>
        </div>
      </div>
    </Portal>
  ) : null;
}
