import React, { FC } from 'react';

export const LogoTextIcon: FC = () => <svg width="89" height="19" viewBox="0 0 89 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0.299805 0.35784H7.84669C9.6882 0.35784 11.3242 0.732998 12.7546 1.48331C14.1851 2.21658 15.2949 3.26531 16.0841 4.62952C16.8734 5.97668 17.268 7.537 17.268 9.31047C17.268 11.0839 16.8734 12.6528 16.0841 14.017C15.2949 15.3642 14.1851 16.4129 12.7546 17.1632C11.3242 17.8965 9.6882 18.2631 7.84669 18.2631H0.299805V0.35784ZM7.69872 15.4494C8.96475 15.4494 10.0746 15.2022 11.0282 14.7076C11.9983 14.1961 12.7382 13.4798 13.2479 12.559C13.774 11.6211 14.0371 10.5383 14.0371 9.31047C14.0371 8.08268 13.774 7.00837 13.2479 6.08752C12.7382 5.14963 11.9983 4.43342 11.0282 3.93889C10.0746 3.42731 8.96475 3.17152 7.69872 3.17152H3.506V15.4494H7.69872Z" fill="black" />
  <path d="M23.3147 6.5991C24.2025 5.16668 25.7645 4.45047 28.0006 4.45047V7.49437C27.7376 7.44321 27.4992 7.41763 27.2854 7.41763C26.0851 7.41763 25.1479 7.78426 24.4738 8.51752C23.7997 9.23374 23.4626 10.2739 23.4626 11.6382V18.2631H20.3798V4.60395H23.3147V6.5991Z" fill="black" />
  <path d="M31.482 18.4422C30.9229 18.4422 30.4461 18.2461 30.0515 17.8538C29.6569 17.4446 29.4596 16.933 29.4596 16.3191C29.4596 15.6882 29.6487 15.1766 30.0269 14.7844C30.4215 14.3922 30.9065 14.1961 31.482 14.1961C32.0574 14.1961 32.5343 14.3922 32.9124 14.7844C33.307 15.1766 33.5043 15.6882 33.5043 16.3191C33.5043 16.933 33.307 17.4446 32.9124 17.8538C32.5178 18.2461 32.041 18.4422 31.482 18.4422Z" fill="black" />
  <path d="M42.4054 18.5189C41.0736 18.5189 39.7829 18.3228 38.5333 17.9306C37.3001 17.5384 36.3218 17.0183 35.5984 16.3703L36.7082 13.7868C37.4152 14.3666 38.2784 14.8441 39.2978 15.2192C40.3337 15.5773 41.3695 15.7564 42.4054 15.7564C43.6879 15.7564 44.6415 15.5432 45.2663 15.1169C45.9075 14.6906 46.2282 14.1278 46.2282 13.4287C46.2282 12.9171 46.0473 12.4993 45.6856 12.1753C45.3403 11.8343 44.8964 11.5699 44.3538 11.3824C43.8112 11.1948 43.0713 10.9816 42.1341 10.7429C40.8187 10.4189 39.75 10.0949 38.9279 9.77089C38.1222 9.44689 37.4235 8.94384 36.8315 8.26173C36.2561 7.56258 35.9683 6.62468 35.9683 5.44805C35.9683 4.459 36.2232 3.56374 36.7329 2.76226C37.259 1.94374 38.04 1.29573 39.0759 0.818261C40.1282 0.340788 41.4106 0.102051 42.9233 0.102051C43.9756 0.102051 45.0114 0.238472 46.0309 0.511315C47.0503 0.784156 47.9299 1.17637 48.6698 1.68795L47.6586 4.27142C46.9023 3.811 46.1131 3.46142 45.291 3.22268C44.4689 2.98395 43.6714 2.86458 42.8987 2.86458C41.6326 2.86458 40.6872 3.08626 40.0624 3.52963C39.454 3.973 39.1499 4.56131 39.1499 5.29458C39.1499 5.80616 39.3225 6.22395 39.6678 6.54795C40.0295 6.87195 40.4817 7.12774 41.0243 7.31531C41.5668 7.50289 42.3067 7.71605 43.2439 7.95479C44.5264 8.26173 45.5787 8.58574 46.4008 8.92679C47.2229 9.25079 47.9217 9.75384 48.4972 10.4359C49.0891 11.1181 49.385 12.0389 49.385 13.1985C49.385 14.1875 49.122 15.0828 48.5958 15.8843C48.0861 16.6857 47.3051 17.3252 46.2528 17.8027C45.2005 18.2802 43.9181 18.5189 42.4054 18.5189Z" fill="black" />
  <path d="M64.359 4.60395L58.7111 18.2631H55.5296L49.8818 4.60395H53.0879L57.182 14.7332L61.3994 4.60395H64.359Z" fill="black" />
  <path d="M70.9076 4.45047C72.8477 4.45047 74.3275 4.93647 75.3469 5.90847C76.3828 6.86342 76.9007 8.31289 76.9007 10.2569V18.2631H73.9904V16.6005C73.6123 17.1973 73.0697 17.6577 72.3627 17.9817C71.6721 18.2887 70.8336 18.4422 69.8471 18.4422C68.8605 18.4422 67.9973 18.2716 67.2574 17.9306C66.5176 17.5725 65.9421 17.0865 65.531 16.4726C65.1364 15.8416 64.9391 15.1339 64.9391 14.3495C64.9391 13.1217 65.3748 12.1412 66.2463 11.4079C67.1341 10.6576 68.5235 10.2825 70.4143 10.2825H73.8178V10.0778C73.8178 9.12289 73.5383 8.38963 72.9793 7.87805C72.4367 7.36647 71.6228 7.11068 70.5376 7.11068C69.7977 7.11068 69.0661 7.23005 68.3426 7.46879C67.6356 7.70752 67.0355 8.04005 66.5422 8.46637L65.3337 6.13868C66.0243 5.593 66.8546 5.17521 67.8247 4.88531C68.7948 4.59542 69.8224 4.45047 70.9076 4.45047ZM70.4883 16.1145C71.2611 16.1145 71.9434 15.9354 72.5353 15.5773C73.1437 15.2022 73.5712 14.6735 73.8178 13.9914V12.4055H70.6363C68.8605 12.4055 67.9727 13.0109 67.9727 14.2216C67.9727 14.8014 68.1946 15.2618 68.6386 15.6029C69.0825 15.9439 69.6991 16.1145 70.4883 16.1145Z" fill="black" />
  <path d="M88.774 17.5213C88.4123 17.8283 87.9684 18.0585 87.4422 18.2119C86.9325 18.3654 86.3899 18.4422 85.8144 18.4422C84.3675 18.4422 83.2495 18.0499 82.4603 17.2655C81.6711 16.4811 81.2765 15.3386 81.2765 13.8379V7.26416H79.1061V4.70626H81.2765V1.58563H84.3593V4.70626H87.8861V7.26416H84.3593V13.7612C84.3593 14.4263 84.5155 14.9378 84.8279 15.2959C85.1403 15.637 85.5925 15.8075 86.1844 15.8075C86.875 15.8075 87.4504 15.6199 87.9108 15.2448L88.774 17.5213Z" fill="black" />
</svg>
