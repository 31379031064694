import React, { FC } from 'react';
import cn from 'classnames';

import './Button.scss';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string; 
}

export const Button: FC<Props> = ({ onClick, children, disabled = false, className }) => {
  const classNames = cn('button', className, { 'button_disabled': disabled });

  return (
    <button className={classNames} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
};