import React, { FC, useCallback, useState } from 'react';
import { Accordion, Footer, Header } from '../../common';
import { Avatar } from '../../common/components/Avatar/Avatar';

import './CompanyPage.scss';
import { avatars, companyChips } from './Subs';

export const CompanyPage: FC = () => {
  const [isVideo, setIsVideo] = useState(false);

  const handleVideoClick = useCallback(() => setIsVideo(!isVideo), [isVideo]);

  return (
    <div className="company-page common-page">
      <Header />
      <div className="company-page__container common-page__container">
        <h5 className="common-page__mini-header company-page__mini-header">О нас</h5>
        <h2 className="common-page__main-header">Миссия — <br className="d-none_ml" /> помогаем встретить любовь</h2>

        <div className="company-page__video-details">
          <div className="company-page__video-container">
            {!isVideo && <div className="company-page__video-container-line"></div>}
            <p className="company-page__video-container-text">
              Об агентстве за 2 минуты
            </p>
            {isVideo ? (
              <iframe width="100%" height="100%" frameBorder="0" src="https://www.youtube.com/embed/IodLH80Hosc?autoplay=1&mute=1"></iframe>
            ) : (
              <button className="company-page__play-btn" onClick={handleVideoClick}></button>
            )}
          </div>

          <div className="company-page__text-container">
            <h3 className="common-page__text-header company-page__text-header">
              Dr.Svat — «второй пилот» <br /> твоих свиданий
        </h3>

            <div className="common-page__delimiter"></div>

            <p className="common-page__text company-page__text">
              На взаимодействие с приложением требуется всего <strong>одна минута в день</strong>. 
              Push-уведомление приходят утром: смотришь анкеты, отвечаешь «да», либо «нет». 
              Если «да», идете на свидание. «Нет» — на следующий день будут новые кандидаты.
            </p>
          </div>
        </div>

        <div className="common-page__triangle-block company-page__triangle-block">
          <h5 className="common-page__mini-header company-page__triangle-block-mini-header">Мы другие</h5>

          <h2 className="common-page__main-header company-page__triangle-block-header">Восемь наших фишек — зацени</h2>
        </div>

        <Accordion className="company-page__accordion" chips={companyChips} />

        <div className="company-page__team">
          <h5 className="common-page__mini-header">Команда</h5>

          <h2 className="common-page__main-header company-page__team-header">
            Привет, друг!<br />
          Мы — те самые люди, которые не спят ночами и пьют кофе ведрами, чтобы заставить тебя ходить на свидания).<br />
          Рады познакомиться!
        </h2>

          <div className="company-page__team-wrapper">
            {avatars.map((avatar, index) => <Avatar key={index} avatar={avatar} className="company-page__team-avatar" />)}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
};