import React, { FC, useMemo } from 'react';
import cn from 'classnames';

import { PointIcon } from '../../icons';

import './Point.scss';

interface Props {
  iconType: 'shield' | 'calendar' | 'person' | 'heart' | 'sexes' | 'sexes' | 'sun' | 'galaxy' | 'saturn' | 'chat';
  reverse?: boolean;
  className?: string;
}

export const Point: FC<Props> = ({ iconType, reverse = false, children, className = '' }) => {
  const containerClasses = useMemo(() => cn('point__container', {
    'point__container_reverse': reverse
  }), [reverse]);

  const pointClasses = useMemo(() => cn('point', className, {
    'point_reverse': reverse
  }), [reverse, className]);

  return (
    <div className={pointClasses}>
      <div className={containerClasses}>
        <div className="point__icon-container">
          <PointIcon type={iconType} />
        </div>

        <div className="point__text-container">
          <p className="text text_m">
            {children}
          </p>
        </div>
      </div>
    </div>
  )
}
