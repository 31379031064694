import React, { FC } from 'react';
import { Avatar as AvatarModel } from '../../models';

import './Avatar.scss';

interface Prop {
  avatar: AvatarModel;
  className?: string;
}

export const Avatar: FC<Prop> = ({ avatar: { job, name, src }, className = '' }) => (
  <div className={`avatar ${className}`}>
    <img src={src} alt={job} className="avatar__image"/>
    <p className="avatar__name">{name}</p>
    <p className="avatar__job">{job}</p>
  </div>
);
