import { CityItemProps } from "./Subs";

export const cities: CityItemProps[] = [
  {
    active: true,
    title: 'Санкт-Петербург',
    className: 'city-item_spb'
  },
  {
    active: true,
    title: 'Моска',
    className: 'city-item_msk'
  },
  {
    active: true,
    title: 'Воронеж',
    className: 'city-item_vrn'
  },
  {
    active: true,
    title: 'Нижний Новгород',
    className: 'city-item_nng'
  },
  {
    active: true,
    title: 'Казань',
    className: 'city-item_kzn'
  },
  {
    active: true,
    title: 'Пермь',
    className: 'city-item_prm'
  },
  {
    active: true,
    title: 'Ростов-на-Дону',
    className: 'city-item_rnd'
  },
  {
    active: true,
    title: 'Саратов',
    className: 'city-item_srt'
  },
  {
    active: true,
    title: 'Самара',
    className: 'city-item_smr'
  },
  {
    active: true,
    title: 'Екатеринбург',
    className: 'city-item_ekb'
  },
  {
    active: true,
    title: 'Краснодар',
    className: 'city-item_krd'
  },
  {
    active: true,
    title: 'Волгоград',
    className: 'city-item_vgg'
  },
  {
    active: true,
    title: 'Уфа',
    className: 'city-item_ufa'
  },
  {
    active: true,
    title: 'Челябинск',
    className: 'city-item_clb'
  },
  {
    active: true,
    title: 'Тюмень',
    className: 'city-item_tmn'
  },
  {
    active: true,
    title: 'Омск',
    className: 'city-item_omk'
  },
  {
    active: true,
    title: 'Красноярск',
    className: 'city-item_kry'
  },
  {
    active: true,
    title: 'Новосибирск',
    className: 'city-item_nvs'
  },
  {
    active: true,
    title: 'Сочи',
    className: 'city-item_sochi'
  },
];