import React, { FC } from 'react';

export const Differences: FC = () => (
  <div className="faq-page__differences">
    <div className="faq-page__text-item">
      <h5 className="faq-page__text-item-header">
        1. Защищаем от фейков и мошенников.
      </h5>

      <p className="faq-page__text-item-line">
        Каждый пользователь при регистрации проходит двойную верификацию личности и ручную модерацию.
      </p>

      <h5 className="faq-page__text-item-header">
        2. Осуществляем индивидуальный подбор.
      </h5>

      <p className="faq-page__text-item-line">
        Подбор осуществляется персонально по 12 параметрам: 4 - социально-физиологических и 8 астрологических.
      </p>

      <h5 className="faq-page__text-item-header">
        3. Используем профессиональная астрологию.
      </h5>

      <p className="faq-page__text-item-line">
        Dr.Svat делает анализ совместимости пары по натальным картам. В это можно верить, можно нет но это работает - проверено.
        Мы подбираем кандидатов на энергетическом уровне, с восприятием мира как и у тебя.
      </p>

      <h5 className="faq-page__text-item-header">
        4. Экономим время от бесконечного выбора и переписок.
      </h5>

      <p className="faq-page__text-item-line">
        Каждый день - один - два кандидата. Скажешь это мало, но в действительности это до 60 кандидатов в месяц, до 730 в год в фоновом режиме. <br />
        PUSH - уведомления приходят утром.
      </p>

      <h5 className="faq-page__text-item-header">
        5. Оберегаем от расфокусировки.
      </h5>

      <p className="faq-page__text-item-line">
        После обоюдной симпатии поиск останавливается, вы будете сконцентрированы только друг на друге и у вас будет 7 дней для личной встречи. Если нет — поиск возобновляется.
      </p>

      <h5 className="faq-page__text-item-header">
        6. Убираем психологические барьеры.
      </h5>

      <p className="faq-page__text-item-line">
        Избавляем от неловкости первого шага в общении - после обоюдной симпатии, наш чат-бот в WhatsApp помогает начать диалог.
      </p>

      <h5 className="faq-page__text-item-header">
        7. Повысили ценность свидания
      </h5>

      <p className="faq-page__text-item-line">
        За свидание платят обе стороны. Когда за встречу с тобой заплатили, ценность возрастает. Кроме того, это дополнительный фильтр по безопасности.
        По внутренней  статистике 97% знакомств перерастают в свидание.
      </p>

    </div>
  </div>
);