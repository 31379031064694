import React, { FC, Fragment, useMemo } from 'react';

import { AppGallery, AppStoreIcon, Footer, Header, LogoBigIcon, PlayStoreIcon, Point } from '../../../common';

export const Mobile: FC = () => {

  const Logo = useMemo(() => {
    if (/Android/i.test(navigator.userAgent)) return <PlayStoreIcon />

    return /iPhone|iPad|iPod/i.test(navigator.userAgent) ? <AppStoreIcon /> : <AppGallery />
  }, []);

  return (
    <Fragment>
      <Header />

      <h1 className="main-page__header">
        Поиск партнера с помощью профессиональной астрологии
      </h1>

      <div className="main-page__phone-container">
        <div className="main-page__store">
          {Logo}
          <LogoBigIcon />
        </div>

        <div className="main-page__points-container">
          <Point iconType="sun">
            Обоюдная симпатия, ощущение счастья в присутствии партнера
          </Point>
          <Point iconType="sexes" reverse={true}>
            Привлекательность на физиологическом уровне
          </Point>
          <Point iconType="galaxy">
            Психологическая совместимость
          </Point>
          <Point iconType="saturn" reverse={true}>
            Исключение подсознательного негативного влияния одного партнера на другого
          </Point>
        </div>

        <div className="main-page__store main-page__store_reverse">
          {Logo}
          <LogoBigIcon />
        </div>

      </div>

      <Footer />
    </Fragment>
  )
};
