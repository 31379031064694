import React from 'react';

import { Chip } from "../../../common/models";
import { Age, Differences, HowMuch, NoResult, Params, Previous, WhatTo } from './Blocks';

export const faqChips: Chip[] = [
  {
    title: 'Чем вы отличаетесь от других приложений знакомств?',
    element: Differences
  },
  {
    title: 'По каким параметрам идет поиск.',
    element: Params
  },
  {
    title: 'Мне сегодня не прислали анкету кандидата.',
    element: NoResult
  },
  {
    title: 'Я передумал(а), хочу познакомиться с предыдущим кандидатом',
    element: Previous
  },
  {
    title: 'Сколько кандидатов мне предложат?',
    element: HowMuch
  },
  {
    title: 'Как узнать время своего рождения?',
    element: WhatTo
  },
  {
    title: 'Есть ли возрастные ограничения?',
    element: Age
  },
];
