import { FC } from "react";
import cn from 'classnames';

import './CityItem.scss';

export interface CityItemProps {
  active?: boolean;
  title: string;
  className: string;
};

export const CityItem: FC<CityItemProps> = ({ active, title, className }) => {

  const cityClass = cn('city-item', className, { 'city-item_active': active });

  return (
    <div className={cityClass}>
      <div className="city-item__dot"></div>
      <p className="city-item__title">{title}</p>
    </div>
  )
};