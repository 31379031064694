import React, { FC } from 'react';

export const WhatTo: FC = () => (
  <div className="faq-page__what-to">
    <p className="faq-page__text-item-line">
      Это можно сделать несколькими способами:
    </p>
    <ul className="faq-page__text-list">
      <li className="faq-page__text-item-line">
        - спросить у родителей;
      </li>
      <li className="faq-page__text-item-line">
        - сделать запрос в архив роддома;
      </li>
      <li className="faq-page__text-item-line">
        - заказать ректификацию гороскопа у профессионального астролога.
      </li>
    </ul>
  </div>
)