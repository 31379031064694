import React, { FC } from 'react';

export const CalendarIcon: FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z" stroke="#F6193D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.333 1.6665V4.99984" stroke="#F6193D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66699 1.6665V4.99984" stroke="#F6193D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 8.3335H17.5" stroke="#F6193D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
