import React, { FC } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC = ({ children }) => {
  const root = document.getElementById('root') || document.body
  return (
    createPortal(
      children,
      root
    )
  )
}
