import React, { FC } from 'react';
import { Footer, Header } from '../../common';
import { BonusPoint } from './Subs';

import firstIcon from '../../assets/images/leadership.svg';
import firstImage from '../../assets/images/first-bonus.webp';
import secondIcon from '../../assets/images/shout.svg';
import secondImage from '../../assets/images/second-bonus.png';
import drSvat from '../../assets/images/drSvat.webp';


import './BonusPage.scss';

export const BonusPage: FC = () => (
  <div className="bonus-page common-page">
    <div className="bonus-page__container common-page__container">
      <Header />
      <h2 className="common-page__main-header bonus-page__main-header">Партнерская программа</h2>

      <BonusPoint
        number="01"
        icon={secondIcon}
        title="Ты блоггер или у тебя много подписчиков?"
        image={secondImage}
        className="bonus-page__point-second"
        reverse
      >
        Давай <strong>станем партнерами. </strong>
        Тебе достаточно разместить ссылку или рекламный баннер «Dr.Svat»,
        через который пользователи будут попадать на основной сайт.
        После регистрации твоих пользователей <strong>ты будешь получать 30% </strong>
        от потраченных ими на «Dr.Svat» средств.
      </BonusPoint>

      <div className="bonus-page__avatar-container">
        <div className="bonus-page__avatar" style={{ backgroundImage: `url(${drSvat})` }} />

        <div className="bonus-page__avatar-text-container">
          <p className="bonus-page__avatar-text">Чтобы обсудить сотрудничество — пиши мне лично</p>

          <a className="bonus-page__avatar-link" href="mailto:iam@drsvat.ru">iam@drsvat.ru</a>
        </div>
      </div>

      <Footer />
    </div>
  </div>
);